import { Injectable } from '@angular/core';
import { catchError, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BaseService } from '../base/base.service';
import { APIROUTES } from 'src/app/shared/constants';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private baseService: BaseService
  ) { }

  //individual registration
  public individualRegistration(body: any){
    return this.baseService.post(environment.baseUrl + APIROUTES.PROFILE.CREATE, body).pipe(catchError(this.errorHandler));
  }

  //pmd registration
  public pmdRegistration(body: any){
    return this.baseService.post(environment.baseUrl + APIROUTES.PMD.PROFILE.CREATE, body).pipe(catchError(this.errorHandler));
  }

  //org registration
  public organizationRegistration(body: any) {
    return this.baseService.post(environment.baseUrl + APIROUTES.ORGANIZATION.PROFILE.CREATE, body).pipe(catchError(this.errorHandler));
  }

  public login(body: any){
    return this.baseService.post(environment.baseUrl + APIROUTES.AUTH.LOGIN, body).pipe(catchError(this.errorHandler));
  }

  public clearLocalStorage() {
    [
      'world_prays_registered_user_data',
      'world_prays_registered_pmd_data',
      'world_prays_registered_org_data',
      'world_prays_token',
      'organization_registration',
      'profile_registration',
      'pmd_registration',
      'world_prays_user',
      'token_issued_date',
      'prayerStarted',
      'firebaseToken',
      'start_time',
      'token_expiration_date'
    ].forEach(
      (key) => {
        localStorage.removeItem(key);
      }
    );
  }

  public logout(){
    return this.baseService.post(environment.baseUrl + APIROUTES.AUTH.LOGOUT, {}).pipe(catchError(this.errorHandler));
  }

  public me(){
    return this.baseService.get(environment.baseUrl + APIROUTES.AUTH.ME,).pipe(catchError(this.errorHandler));
  }

  public refreshToken(){
    return this.baseService.post(environment.baseUrl + APIROUTES.AUTH.REFRESH, {}).pipe(catchError(this.errorHandler));
  }

  public forgotPassword(email: any) {
    return this.baseService.post(environment.baseUrl + APIROUTES.AUTH.PASSWORD.FORGOT, email).pipe(catchError(this.errorHandler));
  }

  public resetPassword(body: any) {
    return this.baseService.post(environment.baseUrl + APIROUTES.AUTH.PASSWORD.RESET, body).pipe(catchError(this.errorHandler));
  }

  public deactivateAccount(body: any){
    return  this.baseService.post(environment.baseUrl + APIROUTES.PROFILE.DEACTIVATEACCOUNT, body).pipe(catchError(this.errorHandler));
  }

  public linkUserToOrganization (organizationID : number)
  {
    return this.baseService.post(environment.baseUrl + APIROUTES.AUTH.LINK_TO_ORGANIZATION, {organization_id : organizationID}).pipe(catchError(this.errorHandler));
  }

  public linkUserToPMD (pmdID : number)
  {
    return this.baseService.post(environment.baseUrl + APIROUTES.AUTH.LINK_TO_PMD, {pmd_id : pmdID}).pipe(catchError(this.errorHandler));
  }

  public getUserTotalHourPerMonth(month_year : string)
  {
    return this.baseService.get(environment.baseUrl + APIROUTES.AUTH.USER_TOTAL_HOUR_PER_MONTH+'?current_month_year='+month_year).pipe(catchError(this.errorHandler));
  }

  public getOrgTotalHourPerMonth(month_year : string)
  {
    return this.baseService.get(environment.baseUrl + APIROUTES.AUTH.ORG_TOTAL_HOUR_PER_MONTH+'?month_year='+month_year).pipe(catchError(this.errorHandler));
  }

  //Method to handle error
  errorHandler(error:any) {
    return of(error);
  }
}
