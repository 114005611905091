import { Component, Inject, NgZone } from '@angular/core';
import { MenuController, Platform } from '@ionic/angular';
import { Router, NavigationEnd } from '@angular/router';
import { register } from 'swiper/element/bundle';
import { TranslationService, WebSocketService, AuthService, StorageService, LoaderService, GlobalPrayerService, ShareDataService } from './shared/services';
import { Capacitor } from '@capacitor/core';
import { NavigationBar } from '@capgo/capacitor-navigation-bar';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { UserType } from './shared/constants';
import { format } from 'date-fns';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';
import { PushNotificationsService } from './shared/services/push-notification/push-notifications.service';
import { LogoutService } from './shared/services/logout-service/logout.service';
import { TranslateService } from '@ngx-translate/core';
import { RTLLanguagesCodes } from './shared/constants/supported-language';
import { SplashScreen } from '@capacitor/splash-screen';
import { NotificationWebsocketService } from './shared/services/notification-websocket-service/notification-websocket.service';
import { InAppPurchaseService } from './shared/services/in-app-purchase.service';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  isMenuDisabled: boolean = false;
  is_admin: boolean = false;

  is_individual : boolean = false;

  menuSegment: 'admin'|'main' = 'main';
  currentUser?: UserType;

  isProductionEnv: boolean = environment.production;

  constructor(
    public translationService: TranslationService,
    private webSocketService: WebSocketService,
    private globalPrayerService: GlobalPrayerService,
    private platform: Platform,
    private zone: NgZone,
    public menu: MenuController,
    public authService: AuthService,
    private loaderService: LoaderService,
    private authStorageService: StorageService,
    private shareData: ShareDataService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document, 
    private logoutService: LogoutService,
    public translate: TranslateService,
    private inappp: InAppPurchaseService,
    public notificationWebSocketService : NotificationWebsocketService
  ) {

    this.translate.onLangChange.subscribe((value) => {
      this.document.querySelector("html")?.setAttribute("lang", value.lang.replace("-", "_"));
      if (RTLLanguagesCodes.includes(value.lang.toLowerCase())) {
        this.document.querySelector("html")?.setAttribute("dir", 'rtl');
      } else {
        this.document.querySelector("html")?.setAttribute("dir", 'ltr');
      }
    });

    this.initializeApp()
    this.setAppLang();
    this.isSuperAdminPage();
    
    App.addListener('resume', () => {
      this.setAppLang();
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Check if the current route contains "/auth/"
        this.menu.enable(!this.router.url.includes('/auth'));

        if (!this.is_admin) {
          this.menuSegment = 'main';
        }
        if (this.authStorageService.shouldRefreshToken) {
          this.refreshToken();
        }

        if (this.authStorageService.user && !this.shareData.prayerTime.value && !this.router.url.includes('tabs/global-prayer')) {
          this.getMyPrayerHour();
        }

        if (this.authStorageService.user) {
          this.currentUser = this.authStorageService.user;
        }
        this.is_admin = !!this.authStorageService.user?.roles_list.map((role) => role.name.toLowerCase()).join(',').includes('superadmin');
        
        if (this.authStorageService.user?.roles_list.length == 1) {
          if(this.authStorageService.user?.roles_list[0].name == 'individual')
          {
            this.is_individual = true;
          }
        }
        
      }
    });

    this.initDeepLink();
  }

  initDeepLink() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(async () => {
        // test if the url is a regular url or our custom url scheme 
        let slug = '/', url = event.url;
        let scheme = (this.platform.is('android') ? 'ltd' : 'mu') + '.ges.worldprays';
        if (url.startsWith('http')) {
            slug = url.split(/\.com/).pop() as string;
        } else if (url.startsWith(scheme)) {
            // like ltd.ges.worldprays://tabs/global-prayer
            let glue = scheme + ':/';
            slug = url.split(glue).pop() as string;
        }
        if (slug) {
          await this.router.navigateByUrl(slug);
        }
      });
    });
  }

  async getMyPrayerHour() {
    this.globalPrayerService.getPrayerTime().subscribe(
      async (res: any) => {
        if (res.success) {
          let targetPrayerTime = typeof res.value == 'string' ? format(new Date(res.value), 'Y/MM/dd HH:mm:ss') : '';
          this.shareData.setPrayerTime(targetPrayerTime);
        }
      }
    )
  }

  setAppLang(){
    const lang = localStorage.getItem('currentLang');
    this.translationService.changeLang(lang? lang : 'en');
  }

  keyboardListener() {
    window.addEventListener('keyboardDidShow', (event: any) => {
      this.document.querySelector('html')?.classList.add('keyboard-open');
    })
    window.addEventListener('keyboardDidHide', () => {
      this.document.querySelector('html')?.classList.remove('keyboard-open');
    })
  }

  async refreshToken() {
    this.authService.refreshToken().subscribe(
      async (res: any) => {
        if (res.error === "Unauthorized.") {
          await this.loaderService.dismiss();
          ['world_prays_token', 'world_prays_user', 'token_issued_date', 'token_expiration_date'].forEach(
            (key) => {
              localStorage.removeItem(key);
            }
          )
          this.router.navigate(['/auth']);
        }
        if (res.success) {
          localStorage.setItem("world_prays_token", res.value.token)
          localStorage.setItem("token_issued_date", res.value.issued_time || new Date().toISOString())
          localStorage.setItem("token_expiration_date", res.value.expiration_time);
        }
      }
    )
  }

  async logout() {
    if (localStorage.getItem('prayerStarted') === 'started') {
      await this.logoutService.logoutEvent.emit();
    }
    await this.loaderService.show('closing_session');
    this.authService.logout().subscribe(
      async (res: any) => {
        if (res.success || res.error === "Unauthorized.") {
          await PushNotificationsService.unregisterNotifications();
          this.authService.clearLocalStorage();
          await this.loaderService.dismiss();          
          this.router.navigate(['/auth']);
        }
      }
    )
    
  }

  async initializeApp(){
    await this.platform.ready();
    this.inappp.getProductsInfos([])
    await this.menu.open();
    await this.menu.close();
    this.keyboardListener();
    if (Capacitor.isNativePlatform()) {
      NavigationBar.setNavigationBarColor({color: '#d7d8da'});
    }
    await SplashScreen.hide({fadeOutDuration: 500});
    //initialize websocket listeners
    this.webSocketService.registerWebSocketListeners();

    // notification websocket
    this.notificationWebSocketService.webSocketListeners();
    
  }

  isSuperAdminPage(): boolean {
    return this.router.url.startsWith('/super-admin');
  }
}
