import { Injectable } from '@angular/core';
import { format, formatDuration, intervalToDuration } from 'date-fns';
import { BehaviorSubject, catchError, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { APIROUTES } from '../../constants';
import { BaseService } from '../base/base.service';

@Injectable({
  providedIn: 'root'
})
export class RoomsService {

  constructor(
    private baseService: BaseService
  ) { }

  private get baseUrl() {
    return environment.baseUrl;
  }

  private get baseEndpoint() {
    return APIROUTES.GLOBAL_PRAYER_ROOM;
  }

  public getNextPrayerRoomOpenTime(){
    return this.baseService.get(this.baseUrl + this.baseEndpoint.NEXT_ROOM_OPEN).pipe(catchError(this.errorHandler));
  }

  public getTopBannerStats(){
    return this.baseService.get(this.baseUrl + this.baseEndpoint.TOP_BANNER_STATS).pipe(catchError(this.errorHandler));
  }

  public searchRooms(offset: number = 0, filterBody?: {queryString?: string, city_id?: number|string, country_code?: string}){
    if (filterBody) {
      if (!filterBody.queryString) {
        delete filterBody.queryString;
      }
      if (!filterBody.city_id) {
        delete filterBody.city_id;
      }
      if (!filterBody.country_code) {
        delete filterBody.country_code;
      }
    }
    return this.baseService.get(this.baseUrl + this.baseEndpoint.SEARCH_ROOMS, {offset, ...filterBody}).pipe(catchError(this.errorHandler));
  }

  public getPersonalPlans(){
    return this.baseService.get(this.baseUrl + this.baseEndpoint.LISTPERSONAL_PLANS).pipe(catchError(this.errorHandler));
  }

  public getChurchPlans(){
    return this.baseService.get(this.baseUrl + this.baseEndpoint.LISTCHURCH_PLANS).pipe(catchError(this.errorHandler));
  }

  public selectPersonalPlan(subscription_id: number, transaction_date: string){
    return this.baseService.post(this.baseUrl + this.baseEndpoint.SELECT_PERSONAL_PLAN, {subscription_id, transaction_date}).pipe(catchError(this.errorHandler));
  }

  public getPersonalRooms(offset: number = 0){
    return this.baseService.get(this.baseUrl + this.baseEndpoint.PERSONAL_ROOMS, {offset}).pipe(catchError(this.errorHandler));
  }

  public getChurchRooms(offset: number = 0){
    return this.baseService.get(this.baseUrl + this.baseEndpoint.CHURCH_ROOMS, {offset}).pipe(catchError(this.errorHandler));
  }

  public getRoomInfoByID(roomId: number){
    return this.baseService.get(environment.baseUrl + APIROUTES.GLOBAL_PRAYER_ROOM.GET_ROOM_BY_ID + '/' + roomId).pipe(catchError(this.errorHandler));
  }

  public createPersonalRoom(body: any){
    return this.baseService.post(this.baseUrl + this.baseEndpoint.CREATE_PERSONAL_ROOM, body).pipe(catchError(this.errorHandler));
  }

  public createChurchRoom(body: any){
    return this.baseService.post(this.baseUrl + this.baseEndpoint.CREATE_CHURCH_ROOM, body).pipe(catchError(this.errorHandler));
  }

  public updatePersonalRoom(id: number, body: any){
    return this.baseService.put(this.baseUrl + this.baseEndpoint.UPDATE_PERSONAL_ROOM + id, body).pipe(catchError(this.errorHandler));
  }

  public roomsUsed(){
    let url = this.baseUrl + this.baseEndpoint.PERSONAL_ROOM_USED;

    return this.baseService.get(url).pipe(catchError(this.errorHandler));
  }

  public updateChurchRoom(id: number, body: any){
    return this.baseService.put(this.baseUrl + this.baseEndpoint.UPDATE_CHURCH_ROOM + id, body).pipe(catchError(this.errorHandler));
  }

  public openRoom(roomId: number){
    return this.baseService.post(this.baseUrl + this.baseEndpoint.OPEN_ROOM + roomId, {}).pipe(catchError(this.errorHandler));
  }

  public closeRoom(roomId: number){
    return this.baseService.post(this.baseUrl + this.baseEndpoint.CLOSE_ROOM + roomId, {}).pipe(catchError(this.errorHandler));
  }

  public joinRoom(roomId: number){
    return this.baseService.post(this.baseUrl + this.baseEndpoint.SELECT_ROOM(roomId), {}).pipe(catchError(this.errorHandler));
  }

  public getPlanDetailsByPlanID(planID: string)
  {
    return this.baseService.get(this.baseUrl + this.baseEndpoint.PRODUCT_BY_PLAN_ID(planID)).pipe(catchError(this.errorHandler));
  }

  public getUserPlan(is_personal : number,status : string = '')
  {
    return this.baseService.get(this.baseUrl + this.baseEndpoint.USER_PLAN, {status,is_personal}).pipe(catchError(this.errorHandler));
  }

  public selectUserPlan(body : any)
  {
    return this.baseService.post(this.baseUrl + this.baseEndpoint.SELECT_PLAN, body).pipe(catchError(this.errorHandler));
  }

  public updateUserPlan(planId : number, is_personal : number, status : string)
  {
    return this.baseService.put(this.baseUrl + this.baseEndpoint.UPDATE_USER_PLAN_STATUS(planId,is_personal), {status}).pipe(catchError(this.errorHandler));
  }

  public getListOfPlan(offset : number = 0)
  {
    return this.baseService.get(this.baseUrl + this.baseEndpoint.LIST_PLAN, {offset}).pipe(catchError(this.errorHandler));
  }

  public getListOfPlanByUser(is_personal : number, offset : number = 0)
  {
    return this.baseService.get(this.baseUrl + this.baseEndpoint.LIST_PLAN_BY_USER, {offset, is_personal}).pipe(catchError(this.errorHandler));
  }

  public convertSecondsToTime(secs: number): string {
    console.log({INIT_VALEUR : secs});
    const hours = Math.floor(secs / 3600);
    const minutes = Math.floor((secs % 3600) / 60);
    // const seconds = secs % 60;


    console.log({TIME_CONVERTED_HOURS : hours});
    console.log({TIME_CONVERTED_MIN : minutes});
    // console.log({TIME_CONVERTED_SECOND : seconds});

    let result = '';

    if (hours > 0) {
        result += `${hours}H`;
    }

    if (minutes > 0) {
        result += `${minutes}MIN`;
    }

    return result;
  }

  public consertSecondsToReadableFormat(second : number)
  {
    console.log({SECOND : second});
    const duration = intervalToDuration({start : 0, end : second * 1000});
    console.log({DURATION : duration});
    console.log({FORMAT_DURATION : formatDuration(duration, {format : ['hours','minutes']})});
    return formatDuration(duration, {format : ['hours','minutes']});
  }



  //Method to handle error
  errorHandler(error:any) {
    return of(error);
  }
}
