export const monthsOfTheYear: {name: string, value: number}[] = [
    {name: 'january', value: 1},
    {name: 'february', value: 2},
    {name: 'march', value: 3},
    {name: 'april', value: 4},
    {name: 'may', value: 5},
    {name: 'june', value: 6},
    {name: 'july', value: 7},
    {name: 'august', value: 8},
    {name: 'september', value: 9},
    {name: 'october', value: 10},
    {name: 'november', value: 11},
    {name: 'december', value: 12},
  ];

  export const monthDates: number[] = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
    16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31
  ];
 
  export declare type hourMarkType = {name: string, value: string};
  export const hourMarks: hourMarkType[] = [
    {name: '00:00 - 00:30', value: '00:00-00:30'},
    {name: '00:30 - 01:00', value: '00:30-01:00'},
    {name: '01:00 - 01:30', value: '01:00-01:30'},
    {name: '01:30 - 02:00', value: '01:30-02:00'},
    {name: '02:00 - 02:30', value: '02:00-02:30'},
    {name: '02:30 - 03:00', value: '02:30-03:00'},
    {name: '03:00 - 03:30', value: '03:00-03:30'},
    {name: '03:30 - 04:00', value: '03:30-04:00'},
    {name: '04:00 - 04:30', value: '04:00-04:30'},
    {name: '04:30 - 05:00', value: '04:30-05:00'},
    {name: '05:00 - 05:30', value: '05:00-05:30'},
    {name: '05:30 - 06:00', value: '05:30-06:00'},
    {name: '06:00 - 06:30', value: '06:00-06:30'},
    {name: '06:30 - 07:00', value: '06:30-07:00'},
    {name: '07:00 - 07:30', value: '07:00-07:30'},
    {name: '07:30 - 08:00', value: '07:30-08:00'},
    {name: '08:00 - 08:30', value: '08:00-08:30'},
    {name: '08:30 - 09:00', value: '08:30-09:00'},
    {name: '09:00 - 09:30', value: '09:00-09:30'},
    {name: '09:30 - 10:00', value: '09:30-10:00'},
    {name: '10:00 - 10:30', value: '10:00-10:30'},
    {name: '10:30 - 11:00', value: '10:30-11:00'},
    {name: '11:00 - 11:30', value: '11:00-11:30'},
    {name: '11:30 - 12:00', value: '11:30-12:00'},
    {name: '12:00 - 12:30', value: '12:00-12:30'},
    {name: '12:30 - 13:00', value: '12:30-13:00'},
    {name: '13:00 - 13:30', value: '13:00-13:30'},
    {name: '13:30 - 14:00', value: '13:30-14:00'},
    {name: '14:00 - 14:30', value: '14:00-14:30'},
    {name: '14:30 - 15:00', value: '14:30-15:00'},
    {name: '15:00 - 15:30', value: '15:00-15:30'},
    {name: '15:30 - 16:00', value: '15:30-16:00'},
    {name: '16:00 - 16:30', value: '16:00-16:30'},
    {name: '16:30 - 17:00', value: '16:30-17:00'},
    {name: '17:00 - 17:30', value: '17:00-17:30'},
    {name: '17:30 - 18:00', value: '17:30-18:00'},
    {name: '18:00 - 18:30', value: '18:00-18:30'},
    {name: '18:30 - 19:00', value: '18:30-19:00'},
    {name: '19:00 - 19:30', value: '19:00-19:30'},
    {name: '19:30 - 20:00', value: '19:30-20:00'},
    {name: '20:00 - 20:30', value: '20:00-20:30'},
    {name: '20:30 - 21:00', value: '20:30-21:00'},
    {name: '21:00 - 21:30', value: '21:00-21:30'},
    {name: '21:30 - 22:00', value: '21:30-22:00'},
    {name: '22:00 - 22:30', value: '22:00-22:30'},
    {name: '22:30 - 23:00', value: '22:30-23:00'},
    {name: '23:00 - 23:30', value: '23:00-23:30'},
    {name: '23:30 - 00:00', value: '23:30-00:00'},
  ];

  export const enum PasswordStrength{
    weak = 'weak',
    medium = 'medium',
    strong = 'strong',
  }

  
export const frequencies = [
  'daily', 'weekly', 'monthly'
];

export const roomRecurringTypeOptions = [
  {label: 'one_off', value: 'one-off'},
  {label: 'recurring', value: 'recurring'}
];

export const roomAudienceTypeOptions = [
  {label: 'everyone', value: 'everyone'},
  {label: 'invitation_only', value: 'invitation-only'}
];

export const roomOpenOptions = [
  {label: 'myself', value: 'myself'},
  {label: 'anyone', value: 'anyone'}
];

export const firstLast = [
  'first', 'last'
];

export const daysOfTheWeek = [
  'sunday', 
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday'
]