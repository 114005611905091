import { Injectable } from '@angular/core';
import { catchError, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BaseService } from '../../base/base.service';
import { APIROUTES } from 'src/app/shared/constants';

@Injectable({
  providedIn: 'root'
})
export class PrayerScheduleService {

  constructor(
    private baseService: BaseService
  ) { }

  createOrganizationPrayerSchedule(body: any){
    return this.baseService.post(environment.baseUrl + APIROUTES.PRAYERSCHEDULE.CREATE.ORGANIZATION, body).pipe(catchError(this.errorHandler));
  }

  getOrgScheduledDates(month: string, year: number, cityId: number){
    return this.baseService.get(environment.baseUrl + APIROUTES.PRAYERSCHEDULE.GETALLORGANIZATION + month + '/' + year, {cityId}).pipe(catchError(this.errorHandler));
  }

  getUserPrayerScheduledDates(orgId: number,userId: number){ // schedule-user-prayer/{userId}/information/selected-date
    return this.baseService.get(environment.baseUrl + APIROUTES.PRAYERSCHEDULE.GET_USER_SELECTED_DATE(orgId,userId)).pipe(catchError(this.errorHandler));
  }

  getOrganizationScheduledDates(orgId: number /*, month: string */, year: number){
    // return this.baseService.get(environment.baseUrl + APIROUTES.PRAYERSCHEDULE.GET + orgId, {month, year}).pipe(catchError(this.errorHandler));
    return this.baseService.get(environment.baseUrl + APIROUTES.PRAYERSCHEDULE.GET_ORGANIZATION_SCHEDULE + orgId +'/'+ year).pipe(catchError(this.errorHandler));
  }

  getOrganizationPrayerSchedule(organizationID : number){
    return this.baseService.get(environment.baseUrl + APIROUTES.PRAYERSCHEDULE.GET_ORGANIZATION_PRAYER_SCHEDULE(organizationID)).pipe(catchError(this.errorHandler));
  }

  getOrganizationScheduledTimespan(organizationId: number, date: {day: number, year: number, month: string}){
    return this.baseService.get(environment.baseUrl + APIROUTES.PRAYERSCHEDULE.ORG_TIMESPAN, {organizationId, ...date}).pipe(catchError(this.errorHandler));
  }

  allocateDateHourIndividual(body: any){
    return this.baseService.post(environment.baseUrl + APIROUTES.PRAYERSCHEDULE.CREATE.JOINORGANIZATIONDATE, body).pipe(catchError(this.errorHandler));
  }

  leaveDateHourSchedule(body : any)
  {
    return this.baseService.post(environment.baseUrl + APIROUTES.PRAYERSCHEDULE.CREATE.LEAVEORGANIZATIONDATE, body).pipe(catchError(this.errorHandler));
  }

  createIndividualPrayerSchedule(body: any){
    // USER_INDIVIDUAL
    return this.baseService.post(environment.baseUrl + APIROUTES.PRAYERSCHEDULE.CREATE.USER_INDIVIDUAL, body).pipe(catchError(this.errorHandler));
  }

  getIndividualsOwnDates(userId: number /*, month: string */, year: number){
    return this.baseService.get(environment.baseUrl + APIROUTES.PRAYERSCHEDULE.GET_INDIVIDUALS_OWN_DATE + year +'/'+ userId).pipe(catchError(this.errorHandler));
  }

  allocataMyOwnDateHour(body: any){ // JOINMYOWNDATE
    return this.baseService.post(environment.baseUrl + APIROUTES.PRAYERSCHEDULE.CREATE.JOINMYOWNDATE, body).pipe(catchError(this.errorHandler));
  }

  getNextPrayDay(){ // GET_NEXT_PRAYER_DAY
    return this.baseService.get(environment.baseUrl + APIROUTES.PRAYERSCHEDULE.GET_NEXT_PRAYER_DAY).pipe(catchError(this.errorHandler));
  } 

  // old endpoint
  getOrganizationPrayerScheduledDates(orgId: number , month: string, year: number){
    // return this.baseService.get(environment.baseUrl + APIROUTES.PRAYERSCHEDULE.GET + orgId, {month, year}).pipe(catchError(this.errorHandler));
    return this.baseService.get(environment.baseUrl + APIROUTES.PRAYERSCHEDULE.GET_ORGANIZATION_SCHEDULE + orgId +'?month='+ month+'&year='+year).pipe(catchError(this.errorHandler));
  }

  // delete prayer date for organization
  deletePrayerScheduleDateForOrganization(organizationID : number, body : any)
  {
    return this.baseService.post(environment.baseUrl + APIROUTES.PRAYERSCHEDULE.DELETE_ORGANIZATION_PRAYER_SCHEDULE(organizationID), body).pipe(catchError(this.errorHandler));
  }

  //Method to handle error
  errorHandler(error:any) {
    return of(error);
  }
}
