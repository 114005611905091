import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import 'cordova-plugin-purchase';
import { StorageService } from './auth/storage/storage.service';
import { Capacitor } from '@capacitor/core';
import { isPast } from 'date-fns';
import { BaseService } from './base/base.service';
import { environment } from 'src/environments/environment';
import { APIROUTES } from '../constants';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { UserSubscription } from '../constants';
import { RoomsService } from './global-prayer/rooms.service';

@Injectable({
  providedIn: 'root'
})
export class InAppPurchaseService {
  store?: CdvPurchase.Store;
  error: ReplaySubject<CdvPurchase.IError> = new ReplaySubject<CdvPurchase.IError>(1, 10000)
  currentSubcriptionPlan = new ReplaySubject<UserSubscription | null>(1);
  // userPlan = new BehaviorSubject<CdvPurchase.Product | null>(null);

  currentPlan$ = this.currentSubcriptionPlan.asObservable();
  // userPlan$ = this.userPlan.asObservable();

  selectedProductID : string = '';

  constructor(
    private platform: Platform,
    private authStorageService: StorageService,
    private baseService: BaseService,
    private roomService : RoomsService
  ) {
    this.initIAP();
  }

  initIAP() {
    const { store, LogLevel } = CdvPurchase;
    CdvPurchase.store.applicationUsername = () => this.authStorageService.user!.email as string;
    store.validator = (receipt, callback) => this.validatePurchase(receipt, callback);
    store.verbosity = LogLevel.DEBUG;
    this.platform.ready().then(
      async () => {
        store.when()
        .productUpdated((product) => {
          console.log("Updated product", {product});
        })
        .receiptsReady(() => {
          console.log("Transactions Receipt ready", {transalctions: store.localTransactions});
        })
        .receiptUpdated(receipt => {
          console.log("Transactions ", {transalctions: store.localTransactions});
          console.log("Recent transaction ", {receipt});
          let transaction = receipt.lastTransaction();
          if(transaction && transaction.state == CdvPurchase.TransactionState.APPROVED && transaction.expirationDate)
          {
            if(isPast(transaction.expirationDate))
            {
              transaction.finish();
            }
          }
        })
        .approved((transaction) => {
          const { TransactionState } = CdvPurchase;
          if (transaction.transactionId === 'appstore.application') {
            transaction.finish();
            return;
          }
          if (transaction && transaction.state == CdvPurchase.TransactionState.APPROVED && transaction.expirationDate) {
            if (isPast(transaction.expirationDate)) {
              transaction.finish();
              return;
            }
          }

          this.selectedProductID = transaction.products[0].id;
          console.log("Approved product proceed for verification", {product: transaction.products[0].id});
          console.log("State of the product: " +transaction.state)
          transaction.verify();
          
        })
        .verified((verfifiedReceipt) => {
          console.log("Verified product", {verfifiedReceipt})
          verfifiedReceipt.finish();
        })
        .unverified(unverified => {
          console.log("Unverified ", {unverified})
        })
        .finished((transaction) => {
          console.log("Finished purchase", {transaction})
          if (transaction.transactionId == 'appstore.application') {
            return;
          }
          
          this.setTransactionProduct(transaction.products[0].id);
          this.updateStoreStates();
          this.addIAPUserID(transaction);
        });

        // Show errors for 10 seconds.
        store.error(error => {
          console.log({ERROR_INIT_APP : error});
          if (error.code === CdvPurchase.ErrorCode.PAYMENT_CANCELLED) {
            console.log('The user cancelled the purchase flow.');
            return;
          }
          this.error.next(error);
        });

        this.store = store;
        await this.registerProducts();
      }
    );
  }

  restoreProducts() {
    return this.store?.restorePurchases();
  }

  async registerProducts() {
    const { ProductType, Platform } = window.CdvPurchase; // window is important
    let onOffProducts = ['li_2879003872', 'su_2598863823', 'po_4328664321'];
    // let onOffProducts = ['li_2879003872'];
    let recurringProducts = ['li_1226972619', 'su_4778613421', 'po_5574413842'];
    // let recurringProducts = ['li_1226972619'];
     
    /* this.store?.register([{
        type: ProductType.CONSUMABLE,
        id: 'li_2879003872',
        platform: Platform.APPLE_APPSTORE,
      },
      {
        type: ProductType.CONSUMABLE,
        id: 'su_2598863823',
        platform: Platform.APPLE_APPSTORE,
      },
      {
        type: ProductType.CONSUMABLE,
        id: 'po_4328664321',
        platform: Platform.APPLE_APPSTORE,
      },
      {
        type: ProductType.PAID_SUBSCRIPTION,
        id: 'li_1538972619',
        platform: Platform.APPLE_APPSTORE,
        group: 'PrayerRoomPlans'
      },
      {
        type: ProductType.PAID_SUBSCRIPTION,
        id: 'su_4778613421',
        platform: Platform.APPLE_APPSTORE,
        group: 'PrayerRoomPlans'
      },
      {
        type: ProductType.PAID_SUBSCRIPTION,
        id: 'po_5574413842',
        platform: Platform.APPLE_APPSTORE,
        group: 'PrayerRoomPlans'
      },

    ]); */
    this.store?.register([
      ...onOffProducts.map(
        (id) => ({id, platform: Platform.APPLE_APPSTORE, type: ProductType.CONSUMABLE})
      ),
      ...onOffProducts.map(
        (id) => ({id, platform: Platform.GOOGLE_PLAY, type: ProductType.CONSUMABLE})
      ),
      ...recurringProducts.map(
        (id) => ({id, platform: Platform.APPLE_APPSTORE, type: ProductType.PAID_SUBSCRIPTION})
      ),
      ...recurringProducts.map(
        (id) => ({id, platform: Platform.GOOGLE_PLAY, type: ProductType.PAID_SUBSCRIPTION})
      )
    ]);
    await this.store?.initialize([Platform.GOOGLE_PLAY, Platform.APPLE_APPSTORE])
  }

  getUsername() {
    this.store!.applicationUsername = () => {
      return this.authStorageService.user!.name
    }
  }

  getProduct(id: string) {
    return this.store?.products.find(
      (product) => id === product.id
    );
  }

  getProductsInfos(ids: string[] = []) {
    if (!ids.length)
      return this.store?.products;

    return this.store?.products.filter(
      (product) => ids.includes(product.id)
    );
  }

  getCurrentSubscriptionPlan() {
    console.log('HERE *** ');
    
    const { ProductType } = CdvPurchase;
    this.getUsername();
    let subscriptionPlan = this.store?.products.find(
      (product) => product.owned && product.type == ProductType.PAID_SUBSCRIPTION
    );
    console.log({CURRENT_PRODUCT : subscriptionPlan});
    return subscriptionPlan;
  }

  subscribePlan(id: string) {
    this.getUsername();
    return this.store?.get(id, Capacitor.getPlatform() === 'ios' ? CdvPurchase.Platform.APPLE_APPSTORE : CdvPurchase.Platform.GOOGLE_PLAY)?.getOffer()?.order();
  }

  purchasePlan(id: string) {
    this.getUsername();
    return this.store?.get(id, Capacitor.getPlatform() === 'ios' ? CdvPurchase.Platform.APPLE_APPSTORE : CdvPurchase.Platform.GOOGLE_PLAY)?.getOffer()?.order();
  }

  getCurrentRecurringPlan() {
    // subscription purchases sorted by expiry date
    const sortedSubscriptions = this.store?.verifiedPurchases
    .filter(purchase => {
      const product = this.store?.get(purchase.id, purchase.platform);
      return product?.type === CdvPurchase.ProductType.PAID_SUBSCRIPTION;
    })
    .sort((a, b) => (a.expiryDate ?? a.purchaseDate ?? 0) - (b.expiryDate ?? b.purchaseDate ?? 0));

    // active one
    const activeSubscription = this.store?.verifiedPurchases.find(purchase => {
      const product = this.store?.get(purchase.id, purchase.platform);
      return product?.type === CdvPurchase.ProductType.PAID_SUBSCRIPTION && product.owned;
    });

    // no active one, show info about the expired one
    const expiredSubscription = activeSubscription ? undefined : sortedSubscriptions?.slice(-1)[0];
    const product = activeSubscription ? this.store?.get(activeSubscription.id, activeSubscription.platform) : undefined;

    return { activeSubscription, expiredSubscription, product };
  }

  updateStoreStates() {
    return this.store?.update();
  }

  restorePurchases() {
    return this.store?.restorePurchases();
  }

  validatePurchase(receipt: CdvPurchase.Validator.Request.Body, callback: CdvPurchase.Callback<CdvPurchase.Validator.Response.Payload>)
  {
    console.log({validate_purchase_ID : this.selectedProductID});
    console.log({validate_purchase : receipt.transaction});
    let receiptData = receipt.transaction?.type == 'ios-appstore' ? (receipt.transaction as CdvPurchase.Validator.Request.ApiValidatorBodyTransactionApple).appStoreReceipt : (receipt.transaction as CdvPurchase.Validator.Request.ApiValidatorBodyTransactionGoogle).receipt;
    console.log({receipt_data : receiptData});
    this.baseService.post(environment.baseUrl + APIROUTES.GLOBAL_PRAYER_ROOM.IAP_SUBSCRIPTION_VALIDATE+'/' + this.selectedProductID,{platform : Capacitor.getPlatform(),receipt : receiptData}).subscribe(
      async (response : any) => {
        if(response.success)
        {
          console.log('Verification response', {response});
          callback({
            ok: true,
            data: response.data
          } as CdvPurchase.Validator.Response.SuccessPayload)
        }
        else{
          callback({
            ok: false,
            status: response.status,
            code: response.code,
            message: response.message,
            res: response
          } as CdvPurchase.Validator.Response.ErrorPayload)
        }
      }
    )
  }

  updateCurrentPlan(plan : UserSubscription)
  {
    console.log({UPDATE_PLAN : plan});
    this.currentSubcriptionPlan.next(plan);
  }

  setTransactionProduct(id: string)
  {
    // let product = CdvPurchase.store.get(id);
    this.roomService.getUserPlan('pending').subscribe(
      (response : any) => {
        console.log({PLAN_RESPONSE : response.value});
        if(response.success && response.value.plan.plan_id == id)
          this.currentSubcriptionPlan.next(response.value);
          // console.log({USER_CURRENT_PLAN_PENDING : userPlan});
      }
    );
    
  }

  manageSubscriptions() {
    return this.store?.manageSubscriptions(
      CdvPurchase.store.defaultPlatform()
    )
  }
  

  addIAPUserID(transaction : CdvPurchase.Transaction)
  {
    // let transactionId = '';
    // if (transaction.platform === CdvPurchase.Platform.APPLE_APPSTORE) {
    //   transactionId = (transaction as any).originalTransactionId;
    // } else {
    //   transactionId = transaction.purchaseId;
    // }
    console.log({TRANSACTION_ADD_USER_ID : transaction});
    console.log({PURCHASE_ID : transaction.purchaseId});
    console.log({ORIGINAL_TRANSACTION_ID : (transaction as any).originalTransactionId});
    this.baseService.put(environment.baseUrl + APIROUTES.GLOBAL_PRAYER_ROOM.ADD_IAP_USER_ID+'/' + transaction.transactionId+'/'+(transaction as any).originalTransactionId,{}).subscribe(
      async (response : any) => {
        console.log({REPONSE_ADD_USER_ID : response.value});
      }
    )
  }

}
