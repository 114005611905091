<div class="ion-padding flex flex-column gap-16 ion-align-items-center">
  <div class="header" [ngClass]="{'ion-text-center': true}" *ngIf="title.length">
    <h2 class="font-family-figtree font-weight-bold">
      {{title}}
    </h2>
  </div>
  <div class="body padding-block-16 padding-inline-9 font-family-figtree" [ngClass]="{'ion-text-center': true}">
    <div [innerHTML]="message"></div>
  </div>
  <div class="footer flex">
    <ion-button (click)="cancel()" shape="round" [color]="cancelButtonColor">
      {{cancelText | uppercase}}
    </ion-button>
    <ion-button (click)="confirm()" shape="round" [color]="confirmButtonColor">
      {{confirmText | uppercase}}
    </ion-button>
  </div>
</div>