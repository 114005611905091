import { Injectable } from '@angular/core';
import { StorageService } from '../storage/storage.service';
import { environment } from 'src/environments/environment';
import { APIROUTES } from 'src/app/shared/constants';
import { HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastController, LoadingController } from '@ionic/angular';
import { Observable, catchError, of, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TokerInterceptorService {

  //JSON object for exclude URLs for avoiding
  excludeURLs: any = {
    login: environment.baseUrl + APIROUTES.AUTH.LOGIN,
  };


  constructor(
    private router: Router, 
    public localStorage: StorageService, 
    public toastController: ToastController, 
    public loading: LoadingController
  ) { }

  //Interceptor method to set bearer token
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let usedRequest: HttpRequest<any> = request;
    if (!request.url.includes(this.excludeURLs.login) && !request.url.includes('google')) {
      usedRequest = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + this.localStorage.isAuthenticated()) });
    }
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';

    usedRequest = usedRequest.clone({ params: usedRequest.params.append('timezone', timezone) });

    return next.handle(usedRequest).pipe(
      catchError((error) => {
        let handled: boolean = false;
        if (error instanceof HttpErrorResponse) {
          this.loading.dismiss().finally(() => null);
          // this.errorToast(error.status == 0 ? error.statusText : error.message);
          switch (error.status) {
            case 401:
              handled = true;
              if (!this.router.url.includes('auth')) {
                this.localStorage.clear();
                return this.router.navigateByUrl("/auth");
              }
              if ((error.statusText || error.message) && !this.router.url.includes('next-prayer-day')) {
                this.errorToast(error.statusText || error.message)
              }
              break;
          }
        }
        if (handled) {
          return of(error);
        } else {
          return throwError(error?.error ? error.error : error);
        }
      })
    );
  }

  //Error toast
  async errorToast(message: any) {
    const toast = await this.toastController.create({
        message: message,
        color: 'danger',
        buttons: ['OK']
      });
    toast.present();
  }
}



