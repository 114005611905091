import { Injectable } from '@angular/core';
import { LoadingController, ModalController, ModalOptions } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmModalComponent } from '../../components/confirm-modal/confirm-modal.component';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  constructor(
    private loadingController: LoadingController,
    private translate: TranslateService,
    private modalController: ModalController
  ) { }

  async show(messageKey: string = 'common.please_wait', backdropDismiss: boolean = false, messageParams: any = {} ) {
    const message = this.translate.instant(messageKey, messageParams);
    await this.dismiss();
    const loading = await this.loadingController.create({
      message,
      backdropDismiss
    });
    await loading.present();
  }

  async dismiss() {
    const loading = await this.loadingController.getTop();
    if (loading) {
      await loading.dismiss();
    }
  }

  async showConfirmModal(
    message: string, title: string = '',
    options?: {
      centerText?: boolean,
      cancelRole?: string,
      cancelText?: string,
      cancelButtonColor?: string,
      confirmRole?: string,
      confirmText?: string,
      confirmButtonColor?: string,
    },
    backdropDismiss: boolean = false
  ) {
    let confirmText = options?.confirmText || this.translate.instant('common.confirm');
    let cancelText = options?.confirmText || this.translate.instant('common.cancel');

    const confirmModal = await this.modalController.create({
      component: ConfirmModalComponent,
      componentProps: {
        title,
        message,
        centerText: options?.centerText || true,
        cancelRole: options?.cancelRole || 'cancel',
        cancelText: cancelText,
        cancelButtonColor: options?.cancelButtonColor || 'light',
        confirmRole: options?.confirmRole || 'confirm',
        confirmText: confirmText,
        confirmButtonColor: options?.confirmButtonColor || 'primary',
      },
      cssClass: 'confirm-modal',
      backdropDismiss
    });
    return confirmModal;
  }
}
