import { Injectable } from '@angular/core';
import { format } from 'date-fns';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShareDataService {
  
  private _prayerTime = new BehaviorSubject<string>('');
  private _nextRoomOpensTime = new BehaviorSubject<string>('');
  private _hasNotification = new BehaviorSubject<boolean>(false);

  constructor() { }

  hasNotification(hasNewNotification: boolean = false) {
    this._hasNotification.next(hasNewNotification);
  }
  hasNewNotification(): Observable<boolean> {
    return this._hasNotification.asObservable();
  }
  
  public get nextRoomOpensTime() {
    return this._nextRoomOpensTime;
  }
  
  public getNextRoomOpensTime() {
    return this._nextRoomOpensTime.asObservable();
  }
  
  public setNextRoomOpensTime(value: string|Date) {
    if (value instanceof Date) {
      value = format(value, 'yyyy/MM/dd HH:mm:ss');
    }
    this._nextRoomOpensTime.next(value);
  }
  
  public get prayerTime() {
    return this._prayerTime;
  }
  
  public getPrayerTime() {
    return this._prayerTime.asObservable();
  }
  
  public setPrayerTime(value: string|Date) {
    if (value instanceof Date) {
      value = format(value, 'yyyy/MM/dd HH:mm:ss');
    }
    this._prayerTime.next(value);
  }

}
