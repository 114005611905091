<ion-app>
  <!-- <ion-split-pane contentId="main-content"> -->
    <ion-menu contentId="main-content" type="overlay" side="end">
      <ion-header class="ion-no-border">
        <ion-toolbar>
          <ion-menu-toggle class="main-menu">
            <div class="ion-padding flex ion-justify-content-end">
              <ion-img class="menu-button" src="/assets/icon/menu/Element_MenuButton.png"></ion-img>
            </div>
          </ion-menu-toggle>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <div class="ion-padding flex ion-justify-content-center">
          <ion-img class="top-banner" src="/assets/img/menu/Elements_Circle Design.png"></ion-img>
        </div>

        <div *ngIf="is_admin" class="ion-text-center">
          <div class="segment-menu-holder ion-padding-start ion-padding-end">
            <ion-segment [(ngModel)]="menuSegment" >
              <ion-segment-button value="main" layout="icon-start">
                <ion-icon name="grid-outline"></ion-icon>
                <ion-label>
                  <ion-text color="dark" class="ion-text-uppercase" translate>
                    common.main_menu
                  </ion-text>
                </ion-label>
              </ion-segment-button>
              <ion-segment-button value="admin" layout="icon-start">
                <ion-icon name="shield-checkmark-outline"></ion-icon>
                <ion-label>
                  <ion-text color="dark" class="ion-text-uppercase" translate>
                    common.super_admin
                  </ion-text>
                </ion-label>
              </ion-segment-button>
            </ion-segment>
          </div>
        </div>

        <ion-list id="inbox-list">
          <ng-container *ngIf="menuSegment === 'admin' && is_admin else regularUser"> <!-- MENU SUPER ADMIN -->
            <!--Dashboard -->
            <ion-menu-toggle auto-hide="false">
              <ion-item button lines="none" detail="false" class="no-container" 
                        routerLink="/super-admin/dashboard" routerDirection="root" routerLinkActive="selected">
                <ion-icon slot="start" name="home"></ion-icon>
                <ion-label translate>menu_dashboard</ion-label>
              </ion-item>
            </ion-menu-toggle>
            <!--Dashboard -->
            
            <!-- Prayer Video -->
            <ion-menu-toggle class="menu-items" [autoHide]="false">
              <ion-item *ngIf="is_admin" class="no-container" detail="false" routerLink="/super-admin/prayer-video" routerDirection="root" routerLinkActive="selected" >
                <ion-icon slot="start" name="caret-forward-circle-outline"></ion-icon>
                <ion-label>{{ 'common.prayer_videos' | translate  }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
            <!-- Prayer Video -->
            <!-- Prayer Card -->
            <ion-menu-toggle class="menu-items" [autoHide]="false">
              <ion-item *ngIf="is_admin" class="no-container" detail="false" routerLink="/super-admin/prayer-card" routerDirection="root" routerLinkActive="selected" >
                <ion-icon slot="start" name="reader-outline"></ion-icon>
                <ion-label>{{ 'common.prayer_cards' | translate  }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
            <!-- Prayer Card -->

            <!--Support -->
            <ion-menu-toggle auto-hide="false">
              <ion-item button lines="none" detail="false" class="no-container" 
                        routerLink="/super-admin/support" routerDirection="root" routerLinkActive="selected">
                <ion-icon slot="start" name="call"></ion-icon>
                <ion-label>{{'common.support' | translate | uppercase }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
            <!--Support -->
            <!-- localization link -->
            <ion-menu-toggle class="menu-items" [autoHide]="false">
              <ion-item class="no-container" detail="false" routerLink="/super-admin/translation" routerDirection="root" routerLinkActive="selected" >
                <ion-icon slot="start" name="language"></ion-icon>
                <ion-label translate>languages</ion-label>
              </ion-item>
            </ion-menu-toggle>
            <!-- localization link -->

            <!-- New Church Registrations -->
            <ion-menu-toggle class="menu-items" [autoHide]="false">
              <ion-item class="no-container" detail="false" routerLink="/super-admin/new-church-register" routerDirection="root" routerLinkActive="selected" >
                <ion-icon slot="start" name="ellipse"></ion-icon>
                <ion-label>{{ 'common.organizations' | translate  }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
            <!-- New Church Registrations -->
            <!-- New PMD Registrations -->
            <ion-menu-toggle class="menu-items" [autoHide]="false">
              <ion-item *ngIf="is_admin" class="no-container" detail="false" routerLink="/super-admin/new-pmd-register" routerDirection="root" routerLinkActive="selected" >
                <ion-icon slot="start" name="ellipse"></ion-icon>
                <ion-label>{{ 'common.pmd' | translate  }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
            <!-- New PMD Registrations -->

            <!-- Communication center -->
            <ion-menu-toggle class="menu-items" [autoHide]="false">
              <ion-item *ngIf="is_admin" class="no-container" detail="false" routerLink="/super-admin/communication-center" routerDirection="root" routerLinkActive="selected" >
                <ion-icon slot="start" name="mail-open-outline"></ion-icon>
                <ion-label>{{'common.communication_center' | translate}}</ion-label>
              </ion-item>
            </ion-menu-toggle>
            <!-- Communication center -->

            <!-- Prayer reports -->
            <ion-menu-toggle class="menu-items" [autoHide]="false">
              <ion-item *ngIf="is_admin" class="no-container" detail="false" routerLink="/super-admin/prayer-reports" routerDirection="root" routerLinkActive="selected" >
                <ion-icon slot="start" name="bar-chart"></ion-icon>
                <ion-label>{{'common.prayer_reports' | translate}}</ion-label>
              </ion-item>
            </ion-menu-toggle>
            <!-- Prayer reports -->

            <!-- Global Prayer Room - Plan for subscription -->
            <ion-menu-toggle class="menu-items" [autoHide]="false">
              <ion-item *ngIf="is_admin" class="no-container" detail="false" routerLink="/super-admin/room-subscription-plan" routerDirection="root" routerLinkActive="selected" >
                <ion-icon slot="start" name="card-outline"></ion-icon>
                <ion-label>{{'common.plan_subscription' | translate}}</ion-label>
              </ion-item>
            </ion-menu-toggle>
            <!-- Global Prayer Room - Plan for subscription -->

          </ng-container>
          <ng-template #regularUser>
            <ng-container *ngIf="is_individual; else not_individual">
              <ion-menu-toggle auto-hide="true">
                <ion-item routerDirection="root" routerLink="/tabs/access" lines="none" detail="false" routerLinkActive="selected" class="no-container">
                  <ng-container *ngIf="currentUser?.profile_picture_url; else defaultIcon">
                    <ion-avatar>
                      <img [src]="currentUser?.profile_picture_url">
                    </ion-avatar>
                  </ng-container>
                  <ng-template #defaultIcon>
                    <ion-icon name="person-circle" slot="start"></ion-icon>
                  </ng-template>
                  <ion-label>{{'nav.menu.my_profile' | translate | uppercase}}</ion-label>
                </ion-item>
              </ion-menu-toggle>
            </ng-container>
            <ng-template #not_individual>
              <ion-menu-toggle auto-hide="true">
                <ion-item class="no-container" button [detail]="false" [routerLink]="[ 'tabs/access/account' ]" >
                  <ng-container *ngIf="currentUser?.profile_picture_url; else defaultIcon">
                    <ion-avatar>
                      <img [src]="currentUser?.profile_picture_url">
                    </ion-avatar>
                  </ng-container>
                  <ng-template #defaultIcon>
                    <ion-icon name="person-circle" slot="start"></ion-icon>
                  </ng-template>
                  <ion-label>{{'nav.menu.my_profile' | translate | uppercase}}</ion-label>
                </ion-item>
              </ion-menu-toggle>
  
              <ion-menu-toggle auto-hide="true">
                <ion-item routerDirection="root" routerLink="/tabs/access" lines="none" detail="false" routerLinkActive="selected" class="no-container">
                  <ion-icon aria-hidden="true" slot="start" src="/assets/icon/tabs/Icon_Access.svg"></ion-icon>
                  <ion-label>{{'nav.tabs.access_level' | translate | uppercase }}</ion-label>
                </ion-item>
              </ion-menu-toggle>
            </ng-template>
            
            <ion-menu-toggle auto-hide="true" *ngIf="!isProductionEnv">
              <ion-item routerDirection="root" routerLink="" lines="none" detail="false" routerLinkActive="selected" class="no-container">
                <ion-icon aria-hidden="true" slot="start" src="/assets/icon/menu/Icon_MediaResources.svg"></ion-icon>
                <ion-label>{{'nav.menu.media_and_resources' | translate | uppercase }}</ion-label>
              </ion-item>
            </ion-menu-toggle>

            <!-- <ion-menu-toggle auto-hide="true" *ngIf="!isProductionEnv">
              <ion-item routerDirection="root" lines="none" detail="false" routerLinkActive="selected" class="no-container">
                <ion-icon aria-hidden="true" slot="start" src="/assets/icon/menu/Icon_AddContact.svg"></ion-icon>
                <ion-label>{{'nav.menu.connections' | translate | uppercase }}</ion-label>
              </ion-item>
            </ion-menu-toggle> -->

            

            <ion-menu-toggle auto-hide="true">
              <ion-item routerDirection="root" routerLink="/tabs/prayer-board" lines="none" detail="false" routerLinkActive="selected" class="no-container">
                <ion-icon aria-hidden="true" slot="start" src="/assets/icon/tabs/Icon_PrayerBoard-06.svg"></ion-icon>
                <ion-label>{{'nav.tabs.prayer_board' | translate | uppercase }}</ion-label>
              </ion-item>
            </ion-menu-toggle>

            <ion-menu-toggle auto-hide="true">
              <ion-item routerDirection="root" routerLink="/tabs/global-prayer" lines="none" detail="false" routerLinkActive="selected" class="no-container">
                <ion-icon aria-hidden="true" slot="start" src="/assets/icon/tabs/Icon_World Prays.svg"></ion-icon>
                <ion-label>{{'nav.tabs.world_prays' | translate | uppercase }}</ion-label>
              </ion-item>
            </ion-menu-toggle>

            <ion-menu-toggle auto-hide="true" *ngIf="!isProductionEnv">
              <ion-item routerDirection="root" routerLink="/tabs/prayer-for-all" lines="none" detail="false" routerLinkActive="selected" class="no-container">
                <ion-icon aria-hidden="true" slot="start" src="/assets/icon/tabs/Icon_PrayforAll.svg"></ion-icon>
                <ion-label>{{'nav.tabs.pray_for_all' | translate | uppercase }}</ion-label>
              </ion-item>
            </ion-menu-toggle>

            <ion-menu-toggle auto-hide="true" *ngIf="!isProductionEnv">
              <ion-item routerDirection="root" lines="none" detail="false" routerLinkActive="selected" class="no-container" routerLink="/tabs/giving">
                <ion-icon aria-hidden="true" slot="start" src="/assets/icon/tabs/Icon_Giving.svg"></ion-icon>
                <ion-label>{{'nav.tabs.giving' | translate | uppercase }}</ion-label>
              </ion-item>
            </ion-menu-toggle>

            <!-- support link -->
            <ion-menu-toggle auto-hide="true">
              <ion-item button lines="none" detail="false" class="no-container"  routerLink="/chat/chat-list" routerDirection="root" routerLinkActive="selected">
                <ion-icon slot="start" name="call"></ion-icon>
                <ion-label>{{'common.support' | translate | uppercase }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
            <!-- support link -->
          </ng-template>

          <ion-menu-toggle auto-hide="true" >
            <ion-item button lines="none" detail="false" class="no-container" (click)="logout()">
              <ion-icon aria-hidden="true" slot="start" name="exit"></ion-icon>
              <ion-label>{{'logout' | translate | uppercase }}</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
    </ion-menu>
    <!-- <ng-container *ngIf="is_admin; else notAdmin">
      
    </ng-container>
    <ng-template #notAdmin>
      <ion-router-outlet id="main-content"></ion-router-outlet>
    </ng-template> -->
    <ion-router-outlet id="main-content" [class.super-admin]="isSuperAdminPage()"></ion-router-outlet>
  <!-- </ion-split-pane> -->
</ion-app>
